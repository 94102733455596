<template>
  <div class="box-card" style="text-align: left;margin-top:0;">
    <!-- 主要内容 -->
    <div>
      <!-- 查询表单 -->
      <el-form label-width="120px" ref="addFormRef" class="demo-ruleForm" :model="addForm" :rules="addFormRules"
        @submit.native.prevent>
        <el-form-item label="权限组：" prop="permission_group_name">
          <el-input maxlength="20" v-model="addForm.permission_group_name"
            @input="(e) => (addForm.permission_group_name = validSpace(e))" placeholder="请输入权限组名称"></el-input>
        </el-form-item>
        <el-form-item label="所属组织：" prop="organization_id">
          <el-select popper-class="my-select" ref="selectOrganization" filterable v-model="addForm.organization_id"
            placeholder="请选择组织" @change="selectOrganization">
            <el-option v-for="item in organizeList" :key="item._id" :label="item.organize_name" :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用人员：" >
          <el-cascader popper-class="my-cascader" v-model="addForm.persons_select" filterable collapse-tags
            :props="defaultProps" :show-all-levels="false" :options="orginsTreelist" ref="orginsTreeRef"
            placeholder="请选择适用人员" @change="personCheckChange" :key="keyValue" >
          </el-cascader>
        </el-form-item>
        <el-form-item label="门禁设备：" prop="device_face_configs">
          <el-table class="tablestyle" size="medium" :data="addForm.device_face_configs" height="400" tooltip-effect="dark" border
            stripe>
            <el-table-column label="设备名称" prop="device_face_name" width="220"></el-table-column>
            <el-table-column label="通行规则" width="170">
              <template slot-scope="scope">
                <el-select size="medium" popper-class="my-select" ref="selectDevice" v-model="scope.row.permission_type"
                  placeholder="请选择通行规则" style="width:150px;">
                  <el-option v-for="item in getOptions('permissionType')" :key="item.value" :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="规则详情" max-width="550">
              <template slot-scope="scope">
                <span v-if="scope.row.permission_type == 1">无限制</span>
                <el-form-item size="medium" v-else-if="scope.row.permission_type == 2"
                  :prop="`device_face_configs[${scope.$index}].permission_time`"
                  :rules="[{ required: scope.row.isCheck, message: '请选择允许通行时间', trigger: 'blur' }]">
                  <el-date-picker v-model="scope.row.permission_time" type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="请选择开始日期"
                    end-placeholder="请选择结束日期">
                  </el-date-picker>
                </el-form-item>
                <div style="display:flex;" v-else-if="scope.row.permission_type == 3">
                  <el-form-item size="medium" style="width:50%;"
                    :prop="`device_face_configs[${scope.$index}].permission_data`"
                    :rules="[{ required: scope.row.isCheck, message: '请选择允许通行日期', trigger: 'blur' }]">
                    <el-date-picker v-model="scope.row.permission_data" type="daterange" value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd" range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择结束日期"
                      style="width:100%">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item size="medium" style="width:50%;"
                    :prop="`device_face_configs[${scope.$index}].permission_data_time`"
                    :rules="[{ required: scope.row.isCheck, message: '请选择允许通行时间', trigger: 'blur' }]">
                    <el-time-picker v-model="scope.row.permission_data_time" is-range value-format="HH:mm:ss"
                      format="HH:mm:ss" range-separator="至" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间"
                      placeholder="请选择选择时间范围" style="width:100%">
                    </el-time-picker>
                  </el-form-item>
                </div>
                <el-form-item size="medium" style="width:70%;"
                  :prop="`device_face_configs[${scope.$index}].permission_count`"
                  :rules="[{ required: scope.row.isCheck, message: '请输入通行次数', trigger: 'blur' }]"
                  v-else-if="scope.row.permission_type == 4">
                  <el-input v-model="scope.row.permission_count" placeholder="请输入次数" style="width:70%"
                    @input="(e) => (scope.row.permission_count = validNumberInteger(e))"></el-input>&nbsp;&nbsp;次
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column width="80">
              <template #header>
                <!--  <el-checkbox v-model="checkedAll" @change="allCheckEvent"></el-checkbox> -->
                配置 <el-checkbox :indeterminate="isIndeterminate" v-model="checkedAll"
                  @change="allCheckEvent"></el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isCheck" @change="CheckItem(scope.row)"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitForm()">保 存</lbButton>
  </div>
</template>

<script>
import { getRoomPageList, getFaceDeviceList, getOrganizationSeleteList, deleteRoom, getRoomDetail, getDetailByOrageni, addRoom, updateRoom } from '@/api/securityApi'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['id'],
  data () {
    // 验证门禁设备是否有选中的
    var selectTable = (rule, value, callback) => {
      if (this.isIndeterminate || this.checkedAll) {
        return callback()
      } else {
        callback(new Error())
      }
    }
    return {
      keyValue: 0, // el-cascader的key值
      addForm: {
        permission_group_name: '', // 权限组名称
        organization_id: null, // 组织id
        organization_name: '', // 组织名称
        persons_select: [], // 适用人员二维数组
        selected_personnelids: [], // 所选人员id;
        selected_personnelids_origin: [], // 原-所选人员id;
        selected_organizaids: [], // 所选组织id集合
        device_face_configs: [], // 门禁设备配置集合
        device_face_configs_length: 0, // 门禁设备长度
        device_face_configs_origin: [], // 原-门禁设备配置集合
        update_status: 0 // 修改状态0-适用人员未变动 1-适用人员变动
      },
      addFormRules: {
        permission_group_name: [{ required: true, message: '请输入权限组名称', trigger: 'blur' }],
        organization_id: [{ required: true, message: '请选择组织', trigger: 'blur' }],
        persons_select: [{ required: true, message: '请选择适用人员', trigger: 'blur' }],
        device_face_configs: [{ required: true, message: '请选择门禁设备', trigger: 'blur' },
          { validator: selectTable, message: '请选择门禁设备', trigger: 'blur' }]
      },
      isEdit: false,
      organizeList: [],
      // tableList: [{
      //   // device_face_id: "001", // 设备id
      //   // device_face_name: "aaaa", // 设备名称
      //   // permission_type: 1, // 通行规则 永久权限 = 1, 按时段 = 2, 按每日时段 = 3, 按次 = 4
      //   // permission_time: [],
      //   // permission_begintime: "", // 开始时间 通行规则为2--（2022-09-09 12:00:00）  通行规则为3- （00:00:00）
      //   // permission_endtime: "", // 结束时间 通行规则为2--（2022-09-09 12:00:00）   通行规则为3- （00:00:00）
      //   // permission_data: [],
      //   // permission_data_time: [],
      //   // permission_beginday: "", // 开始时间（2022-09-09）通行规则为3
      //   // permission_endday: "", // 结束时间（2022-09-09）通行规则为3
      //   // permission_count: 1, // 通行次数  通行规则为4
      //   // isCheck: false,
      // }], // 门禁设备配置列表
      defaultProps: {
        multiple: true,
        children: 'children',
        label: 'name',
        value: 'id'
      },
      // 适用人员
      orginsTreelist: [],
      checkedAll: false, // 是否全选表格中的配置
      isIndeterminate: false // 半选中
    }
  },
  created () {
    this.isEdit = this.id !== 'new'
    if (this.isEdit) {
      this.initEdit()
    }
    // TODO 目前缺少门禁设备接口
    this.requestGroupList()
    this.$nextTick(() => {
      this.fnGetFaceDeviceList()
    })
  },
  mounted () {
    // console.log("-------------id=", typeof (this.id - 0));
  },
  watch: {
    orginsTreelist (newVal) {
      this.keyValue++ // 只要监听到数据源发生变化 ，改变keyValue的值，达到重新渲染的效果
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取权限组详情
    async initEdit () {
      const res = await getRoomDetail({ per_groupid: this.id })
      if (res.Code === 200) {
        const {
          // eslint-disable-next-line camelcase
          permission_group_name,
          // eslint-disable-next-line camelcase
          organization_id,
          // eslint-disable-next-line camelcase
          organization_name,
          // eslint-disable-next-line camelcase
          selected_organizaids,
          // eslint-disable-next-line camelcase
          selected_personnelids,
          // eslint-disable-next-line camelcase
          device_face_configs,
          _id
        } = res.Data
        console.log('res.Data', res.Data)
        var arr = selected_organizaids.concat(selected_personnelids)
        this.seletPersons(organization_id, arr, selected_personnelids)
        // eslint-disable-next-line camelcase
        this.addForm.permission_group_name = permission_group_name
        // eslint-disable-next-line camelcase
        this.addForm.organization_id = organization_id
        // eslint-disable-next-line camelcase
        this.addForm.organization_name = organization_name
        // eslint-disable-next-line camelcase
        this.addForm.selected_organizaids = selected_organizaids
        // eslint-disable-next-line camelcase
        this.addForm.selected_personnelids = selected_personnelids
        // eslint-disable-next-line camelcase
        this.addForm.selected_personnelids_origin = selected_personnelids
        // eslint-disable-next-line camelcase
        this.addForm.device_face_configs = device_face_configs
        // eslint-disable-next-line camelcase
        this.addForm.device_face_configs_origin = device_face_configs
        this.addForm._id = _id
      }
    },
    // 获取适用人员列表
    async seletPersons (id, ids, personnelids) {
      // querytype：查询类型：0.新增(未配置) 1.编辑(当前组织下所有)
      var obj = { organi_id: id, querytype: 0 }
      if (this.isEdit) {
        obj.querytype = 1
        obj.pgroup_id = this.id
      }
      const res = await getDetailByOrageni(obj)
      // console.log('res', res)
      // console.log('ids', ids)
      // console.log('personnelids', personnelids)
      if (res && res.Code === 200) {
        this.orginsTreelist = this.getPersonsList([res.Data].concat())
        console.log('this.orginsTreelist', this.orginsTreelist)
        if (!ids) return
        var k = ''
        this.$nextTick(() => {
          var arr = this.treeToArray(this.orginsTreelist.concat())
          k = arr.filter(item => {
            return ids.find(j => j === item[item.length - 1])
          })
          this.addForm.persons_select = k
          console.log('kk', k)
        })
        this.$nextTick(() => {
          const tag = document.querySelector('.el-cascader__tags')
          if (personnelids.length > 0) {
            tag.innerHTML = `<div class='selectPersonNum'>已选择适用人员${personnelids.length}人<div>`
          } else if (k.length === 0) {
            tag.innerHTML = ''
          } else {
            tag.innerHTML = `<div class='selectPersonNum'>已选择适用人员${0}人<div>`
          }
        })
      }
    },
    // 将人员数据插入树结构中
    getPersonsList (data) {
      data.forEach(item => {
        item.isPerson = item.isPerson ? item.isPerson : false
        if (item.children && item.children.length === 0) {
          if (item.persondata && item.persondata.length > 0) {
            item.children = item.persondata.concat().map(t => {
              t.parent_id = item.id
              t.isPerson = true
              t.name = t.person_name
              t.id = t._id
              t.children = undefined
              return t
            })
          } else {
            item.children = undefined
          }
        } else if (item.children && item.children.length > 0) {
          if (item.persondata && item.persondata.length > 0) {
            item.persondata.concat().forEach(t => {
              t.parent_id = item.id
              t.isPerson = true
              t.name = t.person_name
              t.id = t._id
              t.children = undefined
              item.children.push(t)
            })
          }
          this.getPersonsList(item.children)
        } else {
          item.children = undefined
        }
      })
      return data
    },
    // 将处理后的树控件转化为二维数组
    treeToArray (tree, path = [], result = []) {
      if (!tree) return []
      for (const data of tree) {
        path.push(data.id)
        var isLeaf = !data.children || !data.children.length
        isLeaf ? result.push([...path]) : this.treeToArray(data.children, path, result)
        path.pop()
      }
      return result
    },
    // 适用人员选中节点发生变化时触发
    personCheckChange (val) {
      console.log('personCheckChange----', val)
      // console.log('selected_organizaids---', this.addForm.selected_organizaids);
      var checkNode = ''
      var personId = []
      let personNum = 0
      const tag = document.querySelector('.el-cascader__tags')

      this.$nextTick(() => { // 点击小图标删除需要写nextTick否则更新不及时
        checkNode = this.$refs.orginsTreeRef.getCheckedNodes()
        console.log('checkNode----', checkNode)
        if (checkNode.length === 0) {
          tag.innerHTML = ''
        }
        checkNode.forEach(item => {
          if (item.data.isPerson) {
            personId.push(item.data._id)
            console.log('personId', personId)
            tag.innerHTML = `<div class='selectPersonNum'>已选择适用人员${personId.length}人<div>`
          }
          if (item.data.persondata) {
            personNum = personNum + item.data.persondata.length
            console.log('personNum', personNum)
            tag.innerHTML = `<div class='selectPersonNum'>已选择适用人员${personNum}人<div>`
          }
        })
      })
      // var organizaId = [];
      // checkNode.forEach(item => {
      //   if (item.data.isPerson) personId.push(item.data._id)
      // })
      this.addForm.selected_personnelids = personId
    },
    // 获取所属组织列表
    async requestGroupList () {
      const res = await getOrganizationSeleteList()
      // console.log('requestGroupList----', res);
      if (res && res.Code === 200 && res.Data) {
        this.organizeList = res.Data
      }
    },
    // 获取门禁设备列表
    async fnGetFaceDeviceList () {
      const res = await getFaceDeviceList()
      // console.log('获取门禁设备列表----', res);
      if (res && res.Code === 200 && res.Data && res.Data.length > 0) {
        var arr = res.Data.concat()
        var oldArr = this.addForm.device_face_configs.concat()
        // console.log(oldArr);
        // console.log(arr);
        var num = 0
        arr.forEach(item => {
          var t = oldArr.find(data => item.device_face_serial === data.device_face_id)
          // console.log(t);
          if (t) {
            num++
            item.permission_type = t.permission_type
            item.permission_time = t.permission_type === 2 ? [this.$moment(t.permission_begintime).format('YYYY-MM-DD HH:mm:ss'), this.$moment(t.permission_endtime).format('YYYY-MM-DD HH:mm:ss')] : []
            // console.log(t.permission_begintime);
            // console.log(this.$moment(t.permission_begintime).format("YYYY-MM-DD HH:mm:ss"));
            item.permission_data = t.permission_type === 3 ? [t.permission_beginday, t.permission_endday] : []
            item.permission_data_time = t.permission_type === 3 ? [t.permission_begintime, t.permission_endtime] : ['00:00:00', '23:59:59']
            item.permission_count = t.permission_type === 4 ? t.permission_count : null
            item.isCheck = true
          } else {
            item.permission_type = 1
            item.permission_time = []
            // item.permission_begintime = "";
            // item.permission_endtime = "";
            item.permission_data = []
            item.permission_data_time = ['00:00:00', '23:59:59']
            // item.permission_beginday = "";
            // item.permission_endday = "";
            item.permission_count = null
            item.isCheck = false
          }
          // item.permission_type = 1;
          // item.permission_time = [];
          // // item.permission_begintime = "";
          // // item.permission_endtime = "";
          // item.permission_data = [];
          // item.permission_data_time = [new Date(2022, 10, 1, 0, 0, 0), new Date(2022, 10, 1, 23, 59, 59)];
          // // item.permission_beginday = "";
          // // item.permission_endday = "";
          // item.permission_count = null;
          // item.isCheck = false;
          // oldArr.forEach(data => {
          //   console.log('获取门禁设备列表----', data);
          //   // console.log('获取门禁设备列表--item--', item);
          //   if (item._id === item.device_face_id) {
          //     item.permission_type = data.permission_type;
          //     item.permission_time = [data.permission_begintime, data.permission_endtime];
          //     item.permission_data = [data.permission_beginday, data.permission_beginday];
          //     item.permission_data_time = [data.permission_begintime, data.permission_endtime]
          //     item.permission_count = data.permission_count
          //     item.isCheck = true;
          //   }
          // })
          // console.log('item------', item);
        })
        // console.log('arr---------', arr);
        this.checkedAll = num === arr.length
        // console.log(num);
        this.isIndeterminate = num > 0 && num < arr.length
        this.addForm.device_face_configs = arr
      }
    },
    // 适用人员条目点击
    // handleNodeClick(data, node) {
    //   console.log('适用人员条目点击---', data);
    //   console.log(node);
    //   this.addForm.selected_organizaids = node.checkedKeys;
    //   // this.$refs.selectedOrganizaids.blur();
    // },
    // 选择组织
    selectOrganization (id) {
      this.seletPersons(id)
      this.$nextTick(() => {
        var label = this.$refs.selectOrganization.selectedLabel
        this.addForm.organization_name = label
      })
    },
    // 全选表格数据
    allCheckEvent (val) {
      // console.log(val);
      // console.log(this.checkedAll);
      this.addForm.device_face_configs.forEach(item => {
        item.isCheck = this.checkedAll
      })
      this.isIndeterminate = false
    },
    // 单独选中某一项
    CheckItem (row) {
      var t = this.addForm.device_face_configs.concat().filter(item => item.isCheck)
      this.isIndeterminate = t.length > 0 && t.length < this.addForm.device_face_configs.length
      if (t.length === this.addForm.device_face_configs.length) {
        this.checkedAll = true
      }
    },
    submitForm () {
      console.log('this.addForm.device_face_configs', this.addForm.device_face_configs)
      console.log(' this.addForm.device_face_configs_origin', this.addForm.device_face_configs_origin)
      var that = this
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var checkList = []
          const originDeviceFaceConfigs = this.addForm.device_face_configs_origin
          this.addForm.device_face_configs.forEach(item => {
            var t = {}
            if (item.isCheck) {
              // console.log(item);
              t.device_face_id = item.device_face_serial // 设备序列号
              t.device_face_name = item.device_face_name
              t.permission_type = item.permission_type
              if (item.permission_type === 2) {
                // t.permission_time = item.permission_time;
                t.permission_begintime = item.permission_time[0]
                t.permission_endtime = item.permission_time[1]
              } else if (item.permission_type === 3) {
                // t.permission_data = item.permission_data;
                t.permission_beginday = item.permission_data[0]
                t.permission_endday = item.permission_data[1]
                // t.permission_data_time = item.permission_data_time;
                t.permission_begintime = item.permission_data_time[0]
                t.permission_endtime = item.permission_data_time[1]
              } else if (item.permission_type === 4) {
                // t.permission_data = item.permission_data;
                t.permission_count = item.permission_count
              }
              // 判断改设备id是否在之前的数组中存在，若存在判断permission_type是否改变
              const deviceIndex = originDeviceFaceConfigs.findIndex((element, index) => {
                return item.device_face_serial === element.device_face_id
              })
              if (deviceIndex === -1) {
                // console.log('是添加的新设备')
                t.update_status = 1
              } else {
                const originType = originDeviceFaceConfigs[deviceIndex].permission_type
                if (originType !== item.permission_type) {
                  // console.log('编辑了通行规则')
                  t.update_status = 2
                } else {
                  // console.log('什么也没变')
                  t.update_status = 0
                }
              }
              checkList.push(t)
            }
          })
          // eslint-disable-next-line camelcase
          var selected_organizaids = []
          this.addForm.persons_select.forEach(item => {
            item.forEach(data => {
              var t = this.addForm.selected_personnelids.find(j => j === data)
              if (!t) {
                selected_organizaids.push(data)
              }
            })
          })
          // eslint-disable-next-line camelcase
          selected_organizaids = [...new Set(selected_organizaids)]
          if (selected_organizaids.length > 1) {
            // eslint-disable-next-line camelcase
            selected_organizaids = selected_organizaids.filter(item => item !== this.addForm.organization_id)
          }
          console.log(selected_organizaids)
          var obj = {
            permission_group_name: this.addForm.permission_group_name,
            organization_id: this.addForm.organization_id,
            organization_name: this.addForm.organization_name,
            selected_organizaids: selected_organizaids,
            selected_personnelids: this.addForm.selected_personnelids,
            update_status: this.addForm.update_status,
            device_face_configs: checkList
          }
          const originList = this.addForm.selected_personnelids_origin
          const nowList = this.addForm.selected_personnelids
          // 判断适用人员是否改变
          if (nowList.length === originList.length) {
            nowList.forEach((item, index) => {
              if (originList.indexOf(item) === -1) {
                obj.update_status = 1
              }
            })
          } else {
            obj.update_status = 1
          }

          // 最后再判断是否改变了设备和规则而没有改变适用人员
          if (obj.update_status === 0) {
            const changeDeviceTotal = obj.device_face_configs.reduce((prev, cur) => {
              return prev + cur.update_status
            }, 0)
            const originLength = this.addForm.device_face_configs_origin.length
            const nowLength = obj.device_face_configs.length
            console.log(originLength, nowLength)
            // 在判断是否增加了设备
            if (changeDeviceTotal !== 0) {
              obj.update_status = 2
            } else if (originLength !== nowLength) {
              obj.update_status = 2
            } else {
              obj.update_status = 0
            }
          }
          // console.log(obj);
          if (this.isEdit) {
            obj._id = this.addForm._id
            this.editOld(obj)
          } else {
            this.addNew(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增--保存
    async addNew (obj) {
      console.log('新增', obj)
      // console.log('addNew------', obj);
      const res = await addRoom(obj)
      if (res.Code === 200) this.$emit('closeDialog', false)
    },
    // 编辑--保存
    async editOld (obj) {
      console.log('编辑', obj)
      const res = await updateRoom(obj)
      if (res.Code === 200) this.$emit('closeDialog', false)
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style lang="less" scoped>
.demo-ruleForm {

  .el-select,
  .el-date-editor,
  .el-cascader,
  .el-input {
    width: 90%;
  }
}

.setstyle {
  min-height: 200px;
  padding: 0 !important;
  margin: 0;
  overflow: auto;
  cursor: default !important;
}

.tablestyle {
  width: 90%;

  /deep/ .el-table__body-wrapper {
    max-height: 450px;
  }
}
/deep/.selectPersonNum{
  margin-left: 10px;
}
.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }

  .checkButton3 {
    position: absolute;
    right: 250px;
  }
}</style>
