<template>
  <!-- 通行权限组 -->
  <div class="box-card">
    <div style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="组织：">
          <el-select popper-class="my-select" filterable v-model="queryForm.organization_id" clearable
            placeholder="请选择组织..." @change="onSubmit()">
            <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name"
              :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限组：">
          <el-input maxlength="20" v-model="queryForm.permission_group_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.permission_group_name = validSpace(e))" placeholder="请输入权限组名称"></el-input>
        </el-form-item>

        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="权限组">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('631998b4cc7f0000ae0058d8')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="addAndEdit('new')" v-if="!isAdmin && isShowBtn('631998b4cc7f0000ae0058d6')">
              新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="device_face_configs">
              <el-link type="primary" @click="showDevicesDialog(row)" style="margin-right: 10px"
                v-if="row.device_face_configs.length > 0">{{ row.device_face_configs.length }}</el-link>
              <span v-else>{{ row.device_face_configs.length }}</span>
            </template>
            <template slot-scope="{row}" slot="personls">
              <el-link type="primary" @click="showPersonDialog(row.personls)" style="margin-right: 10px"
                v-if="row.personls.length > 0">{{ row.personls.length }}</el-link>
              <span v-else>{{ row.personls.length }}</span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ validDateTime(row.create_time) }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(row)"
                v-if="isShowBtn('631998b4cc7f0000ae0058d7')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column label="权限组" prop="permission_group_name" min-width="180"></el-table-column>
            <el-table-column prop="organization_name" label="所属组织" min-width="180"></el-table-column>
            <el-table-column label="设备">
              <template slot-scope="scope">
                <el-link type="primary" @click="showDevicesDialog(scope.row)" style="margin-right: 10px"
                  v-if="scope.row.device_face_configs.length > 0">{{ scope.row.device_face_configs.length }}</el-link>
                <span v-else>{{ scope.row.device_face_configs.length }}</span>
              </template>
            </el-table-column>
            <el-table-column label="组织成员">
              <template slot-scope="scope">
                <el-link type="primary" @click="showPersonDialog(scope.row.personls)" style="margin-right: 10px"
                  v-if="scope.row.personls.length > 0">{{ scope.row.personls.length }}</el-link>
                <span v-else>{{ scope.row.personls.length }}</span>
              </template>
            </el-table-column>
            <el-table-column label="来访人员">
              <template slot-scope="scope">
                <el-link type="primary" @click="showVisitorDialog(scope.row.visitor_personls)" style="margin-right: 10px"
                  v-if="scope.row.visitor_personls.length > 0">{{ scope.row.visitor_personls.length }}</el-link>
                <span v-else>{{ scope.row.visitor_personls.length }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="140" >
              <template slot-scope="scope">
                {{ validDateTime(scope.row.create_time) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="130" >
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(scope.row)"
                  v-if="isShowBtn('631998b4cc7f0000ae0058d7')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog title="设备" :isShow="isdialogDevices" width="30%" @close="isdialogDevices = false" footerSlot closeOnClickModal>
      <el-table :data="devicesTable" border stripe class="table_box" height="460">
        <el-table-column type="index" label="序号" width="100" header-align="center" align="center"></el-table-column>
        <el-table-column property="device_face_name" label="设备名称"></el-table-column>
      </el-table>
    </lebo-dialog>
    <lebo-dialog title="成员" :isShow="isPersonDialog" width="30%" footerSlot @close="isPersonDialog = false" closeOnClickModal>
      <el-table :data="personTable" border stripe class="table_box" height="460">
        <el-table-column type="index" label="序号" width="100" header-align="center" align="center"></el-table-column>
        <el-table-column property="person_name" label="姓名"></el-table-column>
        <el-table-column property="phone" label="手机号"></el-table-column>
      </el-table>
    </lebo-dialog>
    <lebo-dialog title="访客" :isShow="isVisitorDialog" width="30%" footerSlot @close="closeAddAccessGroup" closeOnClickModal >
      <el-table :data="visitorTble" border stripe class="table_box" height="460">
        <el-table-column type="index" label="序号" width="100" header-align="center" align="center"></el-table-column>
        <el-table-column property="visitor_name" label="姓名"></el-table-column>
        <el-table-column property="phone" label="手机号"></el-table-column>
        <el-table-column property="index" label="操作" >
          <template slot-scope="scope">
            <lbButton type="err" icon="shanchu1" @click="delVistor(scope.row._id)">删除
            </lbButton>
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 新增通行权限组 -->
    <lebo-dialog append-to-body title="新增通行权限组" :isShow="showAddAccessGroup" width="70%"
      @close="showAddAccessGroup = false" footerSlot>
      <addAccessStrategy :id="'new'" v-if="showAddAccessGroup" @closeDialog="closeAddAccessGroup"></addAccessStrategy>
    </lebo-dialog>
    <!-- 编辑通行权限组 -->
    <lebo-dialog append-to-body title="编辑通行权限组" :isShow="showEditAccessGroup" width="70%"
      @close="showEditAccessGroup = false" footerSlot>
      <addAccessStrategy :id="dialogAccessGroupId" v-if="showEditAccessGroup" @closeDialog="closeAddAccessGroup">
      </addAccessStrategy>
    </lebo-dialog>
  </div>
</template>

<script>
import {
  getRoomPageList,
  getOrganizationSeleteList,
  deleteRoom,
  getRoomDetail,
  getDetailByOrageni,
  addRoom,
  updateRoom
} from '@/api/securityApi'
import { delXZVisitorInfo } from '@/api/administrative'
import { mapGetters, mapState, mapMutations } from 'vuex'
import addAccessStrategy from './components/addAccessStrategy/index.vue'
export default {
  components: { addAccessStrategy },
  data () {
    return {
      // 查询表单
      queryForm: {
        permission_group_name: '',
        organization_id: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      organizationSeleteList: [], // 组织列表下拉列
      devicesTable: [],
      isdialogDevices: false,
      personTable: [],
      visitorTble: [],
      isPersonDialog: false, // 查看组织成员弹框
      isVisitorDialog: false, // 查看来访人员弹框
      showAddAccessGroup: false, // 新增权限组
      showEditAccessGroup: false,
      dialogAccessGroupId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '权限组',
            prop: 'permission_group_name'
          }, {
            label: '所属组织',
            prop: 'organization_name'
          }, {
            label: '设备',
            prop: 'device_face_configs',
            slot: true
          }, {
            label: '人员',
            prop: 'personls',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetOrganizationSeleteList()
    this.requestDataList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'isAdmin', 'getOptions', 'getWayName'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查看人脸设备
    showDevicesDialog (row) {
      this.devicesTable = row.device_face_configs
      this.isdialogDevices = true
    },
    // 查看成员
    showPersonDialog (data) {
      this.personTable = data
      this.isPersonDialog = true
    },
    // 查看来访人员
    showVisitorDialog (data) {
      this.visitorTble = data
      this.isVisitorDialog = true
    },
    // 删除来访人员
    async delVistor (id) {
      console.log(id)
      const res = await delXZVisitorInfo({
        _id: id
      })
      if (res.Code === 200) {
        const filterDel = this.visitorTble.filter(item => {
          return item._id !== id
        })
        this.visitorTble = filterDel
        this.$msg.success(res.Message)
      }
    },
    // 获取组织列表下拉列
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizationSeleteList()
      if (res && res.Code === 200) {
        this.organizationSeleteList = res.Data
      }
    },
    // 获取权限组列表
    async requestDataList () {
      const res = await getRoomPageList(this.queryForm)
      console.log(res.Data.DataList)
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestDataList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.requestDataList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestDataList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.requestDataList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestDataList()
    // },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      console.log('---------multipleSelection---', JSON.stringify(val))
      this.multipleSelection = val
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的通行权限组, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // this.$msg.success('删除成功!');
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i]._id)
              if (arr.length === this.multipleSelection.length) {
                obj._ids = arr
                this.deleteItem(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的通行权限组！')
      }
    },
    // 删除
    async deleteItem (obj) {
      const res = await deleteRoom(obj)
      // console.log(res);
      // this.$msg.error(res.Message);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.requestDataList()
    },
    // 编辑
    batchEdit (row) {
      this.dialogAccessGroupId = row._id
      this.showEditAccessGroup = true
      // this.addAndEdit(row._id)
    },
    // 新增
    addAndEdit (v) {
      this.showAddAccessGroup = true
      // this.$router.push(`/access-strategy-edit/${v}`)
    },
    closeAddAccessGroup () {
      this.showAddAccessGroup = false
      this.showEditAccessGroup = false
      this.isVisitorDialog = false
      this.requestDataList()
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.detailForm {
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
}

.table_box {
  /deep/ .el-table__body-wrapper {
    max-height: 431px;
    overflow-y: auto;
  }
}
</style>
